export const TOKEN_TOP_100 = 'bitcoin,ethereum,tether,bnb,solana,lido-staked-ether,ripple,usdcoin,cardano,dogecoin,shiba-inu,polkadot,toncoin,chainlink,tron,wrapped-bitcoin,matic-network,bitcoin-cash,near-protocol';
export const CONTRACT_ADDRESS = '2qEHjDLDLbuBgRYvsxhc5D6uDWAivNFZGan56P1tpump';
export const CONTRACT_NAME = 'CATTROLL';
export const CONTRACT_ABI = [
  {
      "inputs": [
          {
              "internalType": "string",
              "name": "_name",
              "type": "string"
          },
          {
              "internalType": "string",
              "name": "_symbol",
              "type": "string"
          },
          {
              "internalType": "uint8",
              "name": "_quorum",
              "type": "uint8"
          },
          {
              "internalType": "address[]",
              "name": "_partners",
              "type": "address[]"
          },
          {
              "internalType": "uint256[]",
              "name": "_shares",
              "type": "uint256[]"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": true,
              "internalType": "address",
              "name": "owner",
              "type": "address"
          },
          {
              "indexed": true,
              "internalType": "address",
              "name": "spender",
              "type": "address"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
          }
      ],
      "name": "Approval",
      "type": "event"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": true,
              "internalType": "address",
              "name": "target",
              "type": "address"
          },
          {
              "indexed": false,
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
          },
          {
              "indexed": true,
              "internalType": "uint256",
              "name": "nonce",
              "type": "uint256"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "timestamp",
              "type": "uint256"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "executionTimestamp",
              "type": "uint256"
          },
          {
              "indexed": false,
              "internalType": "bytes32",
              "name": "txHash",
              "type": "bytes32"
          },
          {
              "indexed": false,
              "internalType": "bytes[]",
              "name": "sigs",
              "type": "bytes[]"
          }
      ],
      "name": "Executed",
      "type": "event"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": true,
              "internalType": "address",
              "name": "target",
              "type": "address"
          },
          {
              "indexed": false,
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
          },
          {
              "indexed": true,
              "internalType": "address",
              "name": "executor",
              "type": "address"
          }
      ],
      "name": "ExecutedP",
      "type": "event"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": true,
              "internalType": "address",
              "name": "",
              "type": "address"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "name": "Received",
      "type": "event"
  },
  {
      "anonymous": false,
      "inputs": [
          {
              "indexed": true,
              "internalType": "address",
              "name": "from",
              "type": "address"
          },
          {
              "indexed": true,
              "internalType": "address",
              "name": "to",
              "type": "address"
          },
          {
              "indexed": false,
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
          }
      ],
      "name": "Transfer",
      "type": "event"
  },
  {
      "inputs": [],
      "name": "VOTING_DURATION",
      "outputs": [
          {
              "internalType": "uint32",
              "name": "",
              "type": "uint32"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "a",
              "type": "address"
          }
      ],
      "name": "addAdapter",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "p",
              "type": "address"
          }
      ],
      "name": "addPermitted",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "owner",
              "type": "address"
          },
          {
              "internalType": "address",
              "name": "spender",
              "type": "address"
          }
      ],
      "name": "allowance",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "spender",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
          }
      ],
      "name": "approve",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "account",
              "type": "address"
          }
      ],
      "name": "balanceOf",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "_to",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
          }
      ],
      "name": "burn",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "_recipient",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "_share",
              "type": "uint256"
          },
          {
              "internalType": "address[]",
              "name": "_tokens",
              "type": "address[]"
          },
          {
              "internalType": "address[]",
              "name": "_adapters",
              "type": "address[]"
          },
          {
              "internalType": "address[]",
              "name": "_pools",
              "type": "address[]"
          }
      ],
      "name": "burnLp",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "burnable",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint8",
              "name": "_q",
              "type": "uint8"
          }
      ],
      "name": "changeQuorum",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "checkSubscription",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "a",
              "type": "address"
          }
      ],
      "name": "containsAdapter",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "p",
              "type": "address"
          }
      ],
      "name": "containsPermitted",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "decimals",
      "outputs": [
          {
              "internalType": "uint8",
              "name": "",
              "type": "uint8"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "spender",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "subtractedValue",
              "type": "uint256"
          }
      ],
      "name": "decreaseAllowance",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "disableBurning",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "disableMinting",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "_target",
              "type": "address"
          },
          {
              "internalType": "bytes",
              "name": "_data",
              "type": "bytes"
          },
          {
              "internalType": "uint256",
              "name": "_value",
              "type": "uint256"
          },
          {
              "internalType": "uint256",
              "name": "_nonce",
              "type": "uint256"
          },
          {
              "internalType": "uint256",
              "name": "_timestamp",
              "type": "uint256"
          },
          {
              "internalType": "bytes[]",
              "name": "_sigs",
              "type": "bytes[]"
          }
      ],
      "name": "execute",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "_target",
              "type": "address"
          },
          {
              "internalType": "bytes",
              "name": "_data",
              "type": "bytes"
          },
          {
              "internalType": "uint256",
              "name": "_value",
              "type": "uint256"
          }
      ],
      "name": "executePermitted",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "name": "executedPermitted",
      "outputs": [
          {
              "internalType": "address",
              "name": "target",
              "type": "address"
          },
          {
              "internalType": "bytes",
              "name": "data",
              "type": "bytes"
          },
          {
              "internalType": "uint256",
              "name": "value",
              "type": "uint256"
          },
          {
              "internalType": "uint256",
              "name": "executionTimestamp",
              "type": "uint256"
          },
          {
              "internalType": "address",
              "name": "executor",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "bytes32",
              "name": "",
              "type": "bytes32"
          }
      ],
      "name": "executedTx",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "uint256",
              "name": "_index",
              "type": "uint256"
          }
      ],
      "name": "executedVotingByIndex",
      "outputs": [
          {
              "components": [
                  {
                      "internalType": "address",
                      "name": "target",
                      "type": "address"
                  },
                  {
                      "internalType": "bytes",
                      "name": "data",
                      "type": "bytes"
                  },
                  {
                      "internalType": "uint256",
                      "name": "value",
                      "type": "uint256"
                  },
                  {
                      "internalType": "uint256",
                      "name": "nonce",
                      "type": "uint256"
                  },
                  {
                      "internalType": "uint256",
                      "name": "timestamp",
                      "type": "uint256"
                  },
                  {
                      "internalType": "uint256",
                      "name": "executionTimestamp",
                      "type": "uint256"
                  },
                  {
                      "internalType": "bytes32",
                      "name": "txHash",
                      "type": "bytes32"
                  },
                  {
                      "internalType": "bytes[]",
                      "name": "sigs",
                      "type": "bytes[]"
                  }
              ],
              "internalType": "struct Dao.ExecutedVoting",
              "name": "",
              "type": "tuple"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "factory",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "getAdapters",
      "outputs": [
          {
              "internalType": "address[]",
              "name": "",
              "type": "address[]"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "getExecutedPermitted",
      "outputs": [
          {
              "components": [
                  {
                      "internalType": "address",
                      "name": "target",
                      "type": "address"
                  },
                  {
                      "internalType": "bytes",
                      "name": "data",
                      "type": "bytes"
                  },
                  {
                      "internalType": "uint256",
                      "name": "value",
                      "type": "uint256"
                  },
                  {
                      "internalType": "uint256",
                      "name": "executionTimestamp",
                      "type": "uint256"
                  },
                  {
                      "internalType": "address",
                      "name": "executor",
                      "type": "address"
                  }
              ],
              "internalType": "struct Dao.ExecutedPermitted[]",
              "name": "",
              "type": "tuple[]"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "getExecutedVoting",
      "outputs": [
          {
              "components": [
                  {
                      "internalType": "address",
                      "name": "target",
                      "type": "address"
                  },
                  {
                      "internalType": "bytes",
                      "name": "data",
                      "type": "bytes"
                  },
                  {
                      "internalType": "uint256",
                      "name": "value",
                      "type": "uint256"
                  },
                  {
                      "internalType": "uint256",
                      "name": "nonce",
                      "type": "uint256"
                  },
                  {
                      "internalType": "uint256",
                      "name": "timestamp",
                      "type": "uint256"
                  },
                  {
                      "internalType": "uint256",
                      "name": "executionTimestamp",
                      "type": "uint256"
                  },
                  {
                      "internalType": "bytes32",
                      "name": "txHash",
                      "type": "bytes32"
                  },
                  {
                      "internalType": "bytes[]",
                      "name": "sigs",
                      "type": "bytes[]"
                  }
              ],
              "internalType": "struct Dao.ExecutedVoting[]",
              "name": "",
              "type": "tuple[]"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "getPermitted",
      "outputs": [
          {
              "internalType": "address[]",
              "name": "",
              "type": "address[]"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "_target",
              "type": "address"
          },
          {
              "internalType": "bytes",
              "name": "_data",
              "type": "bytes"
          },
          {
              "internalType": "uint256",
              "name": "_value",
              "type": "uint256"
          },
          {
              "internalType": "uint256",
              "name": "_nonce",
              "type": "uint256"
          },
          {
              "internalType": "uint256",
              "name": "_timestamp",
              "type": "uint256"
          }
      ],
      "name": "getTxHash",
      "outputs": [
          {
              "internalType": "bytes32",
              "name": "",
              "type": "bytes32"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "spender",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "addedValue",
              "type": "uint256"
          }
      ],
      "name": "increaseAllowance",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "lp",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "_to",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
          }
      ],
      "name": "mint",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "mintable",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "_sender",
              "type": "address"
          },
          {
              "internalType": "address",
              "name": "_recipient",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "_amount",
              "type": "uint256"
          }
      ],
      "name": "move",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "name",
      "outputs": [
          {
              "internalType": "string",
              "name": "",
              "type": "string"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "numberOfAdapters",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "numberOfPermitted",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "quorum",
      "outputs": [
          {
              "internalType": "uint8",
              "name": "",
              "type": "uint8"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "a",
              "type": "address"
          }
      ],
      "name": "removeAdapter",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "p",
              "type": "address"
          }
      ],
      "name": "removePermitted",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "_lp",
              "type": "address"
          }
      ],
      "name": "setLp",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "shop",
      "outputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "symbol",
      "outputs": [
          {
              "internalType": "string",
              "name": "",
              "type": "string"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "name": "transfer",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "pure",
      "type": "function"
  },
  {
      "inputs": [
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          },
          {
              "internalType": "address",
              "name": "",
              "type": "address"
          },
          {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
          }
      ],
      "name": "transferFrom",
      "outputs": [
          {
              "internalType": "bool",
              "name": "",
              "type": "bool"
          }
      ],
      "stateMutability": "pure",
      "type": "function"
  },
  {
      "stateMutability": "payable",
      "type": "receive"
  }
]
export const LINK_X = 'https://twitter.com/x3000meme';
export const LINK_TELE = 'https://t.me/x3000meme';
export const BUY_NOW_LINK = '#soon-linked';