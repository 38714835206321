import './embedVideo.css';
const EmbedVideo = ({ url, title = '', className, style, background }) => {

  if(background) {
    return <video id="background-video" autoPlay={true} loop={true}>
      <source src={url} type="video/mp4" />
    </video>
  }

  return <div style={ style } className={`responsive-iframe ${className}`}>
    <iframe className='iframe-video' frameBorder={0} src={url} allow="autoplay; fullscreen; picture-in-picture" title={title}></iframe>
  </div>
}

export default EmbedVideo;