import React, { useState, useEffect } from 'react';
import './index.css'

const CountdownTimer = ({ endDate, link, childen }) => {
  const calculateTimeLeft = () => {
    const difference = new Date(endDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (time) => (time < 10 ? `0${time}` : time);

  return (
    <div className="row cd-timer-container">
      <div className="col-md-12">
          <div className="cd-count-down-timer font-large">
            {timeLeft.days > 0 && <span className="cd-box box-days">{formatTime(timeLeft.days)}</span>}
            <span className="cd-box">{formatTime(timeLeft.hours || 0)}</span>
            <span className="cd-box">{formatTime(timeLeft.minutes || 0)}</span>
            <span className="cd-box">{formatTime(timeLeft.seconds || 0)}</span>
          </div>
          <div className="cd-count-down-timer cd-text">
            {timeLeft.days > 0 && <span className="cd-box box-days">day</span>}
            <span className="cd-box">hrs</span>
            <span className="cd-box">mins</span>
            <span className="cd-box">sec</span>
          </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
