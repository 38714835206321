import "./roadmap.css";
import circle from "./circle.png";

const RoadMap = () => {
  return (
    <div id="roadmap" className="roadmap">
      <div className="flex-parent">
        <div className="input-flex-container">
          <div className="input road-1">
            <span data-info="Phrase 1"></span>
          </div>
          <div className="input road-2 active">
            <span data-info="Phrase 2"></span>
          </div>
          <div className="input road-3">
            <span data-info="Phrase 3"></span>
          </div>
          <div className="input road-4">
            <span data-info="Phrase 4"></span>
          </div>
          <div className="input road-5">
            <span data-info="Phrase 5"></span>
          </div>
        </div>

        <div className="position-relative">
          <img className="glow-effects-circle-image-1" src={circle} alt="glow" />
          <div className="description-flex-container">
            <div className="map-5 active">
              <div className="history-tl-container">
                <h3 className="mb-3">2024</h3>
                <ul className="tl">
                <li className="tl-item">
                  <div className="item-title">Phrase 1: Pre-Launch Setup</div>
                  <div className="item-detail">
                    content: Finalize CatTroll concept, tokenomics, and branding, set up community channels (Discord, Telegram, Twitter), launch website **Pump.Fun** with Solana focus, develop and audit smart contract for Solana.
                  </div>
                </li>

                <li className="tl-item">
                  <div className="item-title">Phrase 2: Official Token Launch on Raydium</div>
                  <div className="item-detail">
                    content: Launch CatTroll token exclusively on Raydium DEX, promote with viral memes, influencer collaborations, and social media blitz, airdrop and giveaways for early adopters, list CatTroll on CoinGecko and CoinMarketCap.
                  </div>
                </li>

                <li className="tl-item">
                  <div className="item-title">Phrase 3: First Pump & Expansion</div>
                  <div className="item-detail">
                    content: Launch liquidity and farming pools on Raydium, release CatTroll-themed NFT collection, secure partnerships within the Solana ecosystem, hold charity initiatives focused on animal welfare.
                  </div>
                </li>

                <li className="tl-item">
                  <div className="item-title">Phrase 4: Market Domination & X3000 Target</div>
                  <div className="item-detail">
                    content: Run exclusive events and promotions on Raydium DEX, release CatTroll merchandise for brand awareness, achieve X3000 target with community celebrations, burn a portion of supply for scarcity.
                  </div>
                </li>

                <li className="tl-item">
                  <div className="item-title">Phrase 5: Legacy & Future Development</div>
                  <div className="item-detail">
                    content: Launch play-to-earn game (P2E) in the Solana ecosystem, transition to a decentralized autonomous organization (DAO), explore cross-chain partnerships while staying Solana-native.
                  </div>
                </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
