import './index.css';
function CallToAction({ link, onlyButton = false }){
  return <div className="col-md-12 call-to-action">
    {
      !onlyButton && (
        <><h2>🚀 Don't Miss Out on the Pump! 🚀</h2><p>Join the **Pump.Fun** revolution now and be one of the first to buy **CatTroll Token**. The earlier you buy, the more you stand to gain as we dominate the Solana ecosystem! 💥</p><p>By investing early, you maximize your chances of multiplying your holdings up to **X3000** — that's right, **X3000** potential returns! 🌟</p><p>🔥 Exclusive offer for early adopters: Get in on the ground floor and ride the wave of success! 🔥</p><p><strong>Hurry, limited supply and massive gains await!</strong> 🚀</p></>
      )
    }
    <a rel="noreferrer" target='_blank' href={`${link ? link : '#soon'}`} className="cta-button">Buy Now</a>
  </div>
}

export default CallToAction;