import React, { useState } from "react";
import "./index.css";

const CopyArea = ({content}) => {
    const [isCopied, setIsCopied] = useState(false);

    const copyToClipboard = async (content) => {
        try {
            // Copy the text inside the text field
            navigator.clipboard.writeText(content).then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
            })
        } catch (err) {
            alert('Failed to copy. Please press and hold 2s to copy!');
        }
    };

    return (
        <div className="copy-area">
            <div className="code-box">
                <pre className="text-white"><i>{content}</i></pre>
            </div>
            <button className="copy-button" onClick={() => copyToClipboard(content)}>
                {isCopied ? "Copied Contract!" : "Copy contract"}
            </button>
        </div>
    );
};

export default CopyArea;
