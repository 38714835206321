import './footer.css'
import WidgetFooter from '../WidgetFooter';
import Social from '../Social';

const Footer = () => {
  const widget0 = {
    title: '',
    list: [
      {
        label: 'White paper',
        // link: 'https://tomcatdao.dev/white-paper/'
        link: '#'
      },
      {
        label: 'News',
        // link: 'https://tomcatdao.dev/news/'
        link: '#'
      }
    ]
  }

  const widget1 = {
    title: 'Dex',
    list: [
      {
        label: 'Pump.fun',
        link: '#soon'
      },
      {
        label: 'Raydium',
        link: '#soon'
      },
    ]
  }

  const widget2 = {
    title: 'Cat Troll',
    list: [
      {
        label: 'CoinGecko',
        link: '#soon'
      },
      {
        label: 'CoinMarketCap',
        link: '#soon'
      }
    ]
  }

  const widget3 = {
    title: 'Exchanges',
    list: [
      {
        label: 'Gate.io',
        link: '#soon'
      },
      {
        label: 'Binance',
        link: '#soon'
      },
    ]
  }

  const widget4 = {
    title: 'Roadmap',
    list: [
      {
        label: 'Road map',
        link: '#roadmap'
      },
    ]
  }

  return <footer className='footer container-fluid'>
    <div className='row'>
      <div className='col col-md-6 col-xs-12'>
        <Social />
        <div className='flex-v'>
          <WidgetFooter data={widget0}/>
        </div>
      </div>
      <div className='col col-md-6 col-xs-12'>
        <div className='grid grid-4 m-grid-2'>
          <WidgetFooter data={widget1} />
          <WidgetFooter data={widget2} />
          <WidgetFooter data={widget3} />
          <WidgetFooter data={widget4} />
        </div>
      </div>
    </div>
    
  </footer>
}

export default Footer;