import './index.css';
import { SwapWidget, lightTheme, darkTheme } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'
// import { useAppState } from '../../provider/AppContext';

// Use the native token of the connected chain as the default input token
const NATIVE = 'NATIVE' // Special address for native token

const width = window.screen.width > 400 ? 400 : window.screen.width - 20;

function Swap() {
  // const {theme} = useAppState();

  return (
    <div className="tcd-swap">
      <SwapWidget
        width={width}
        theme={lightTheme}
        defaultInputTokenAddress={NATIVE}
      />
    </div>
  );
}

export default Swap;
