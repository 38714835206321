import './App.css';
import Header from "./components/Header/";
import Swap from "./components/Swap/";
import Home from "./components/Home";
import Token from "./components/Token/";
import { Routes, Route } from "react-router-dom";
import { AppContextProvider } from './provider/AppContext';

function App() {
  return (
    <AppContextProvider>
      <div className="App">
        <Header />
        <div className="tcd-main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/swap" element={<Swap />} />
            <Route path="/token" element={<Token />} />
            <Route path="*" element={<Swap />} />
          </Routes>
        </div>
      </div>
    </AppContextProvider>
  );
}

export default App;
