import './widgetFooter.css';

const WidgetFooter = (props) => {
  const { data } = props;
  return (
    <div className="widget-container">
      <h3 className='widget-title'>{data.title}</h3>
      <ul className="widget-list">
        {data.list.map((item, i) => {
          return (
            <li key={`${data.title}_${i}`} className="widget-item">
              <a target='_blank' rel="noreferrer" href={item.link}>{item.label}</a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default WidgetFooter;