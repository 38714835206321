import './index.css';

import React from "react";
import Logo from "../../images4/logo.png";
import CallToAction from '../CallToAction';
import Social from '../Social';

function Header() {
  return (
    <header>
      <div className="tcd-header-left">
        <a href="/">
          <img src={Logo} alt="logo" className="logo" />
        </a>
      </div>
      <div className={`tcd-main-menu`}>
      
      { window.IS_SHOW_CONTRACT && <CallToAction onlyButton={true} link={window.BUY_NOW_LINK} />}

      </div>
      <div className='tcd-header-right'>
        <Social />
      </div>
    </header>
  );
}

export default Header;
