import { createContext, useContext, useState } from "react"

const AppContext = createContext(null);

export const AppContextProvider = ({children}) => {
  const [addressWallet, setAddressWallet] = useState('');
  const [isConnect, setIsConnect] = useState(false);
  const [theme, setTheme] = useState('dark');

  const providerValue = {
    isConnect,
    setIsConnect,
    addressWallet,
    setAddressWallet,
    theme,
    setTheme
  }

  return <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
}

export const useAppState = () => {
  const context = useContext(AppContext);
  if(!context) throw Error('Use useAppState in AppContextProvider');

  return context;
}