import "./index.css";
import imgCattroll from "../../images4/cattroll.png";
import imglocked from "../../images4/lockedliquidity.png";
import catlap from "../../images4/catlap.png";
import imgCommunity from "../../images4/Community.png";

import CountdownTimer from "../Countdown";
import EmbedVideo from "../EmbedVideo";
import RoadMap from "../Roadmap";
import Footer from "../Footer";
import CallToAction from "../CallToAction";
import CopyArea from "../CopyArea";

const Home = () => {
  function toggleStory() {
    const story = document.querySelector(".story");
    const readMore = document.querySelector(".read-more");
    if (story.style.display === "none" || story.style.display === "") {
      story.style.display = "block";
      readMore.textContent = "Read Less";
    } else {
      story.style.display = "none";
      readMore.textContent = "Read More";
    }
  }

  const renderTitle = (title) => {
    return (
      <div className="row text-center justify-content-center">
        <div className="col-lg-12">
          <h2 className="main-title">{title}</h2>
        </div>
      </div>
    );
  };

  const endDate = window.END_DATE;

  return (
    <div className="meme-home">
      {/* Introducing  */}
      <section className="container introducing">
        {window.IS_SHOW_CONTRACT ? (
          <CopyArea content={window.CONTRACT_ADDRESS} />
        ) : (
          <></>
        )}

        {renderTitle("Cat Troll Story", true)}
        <div className=" row justify-content-center align-content-center align-items-start text-center">
          <div className="col-lg-6 col-sm-12">
            <img className="img-fluid" alt="" src={imgCattroll} />
          </div>
          <div className="col-lg-6 col-sm-12 text-left">
            <div className="cattroll-story">
              <p>
                <strong>Doge:</strong> Cat, I’m too heavy to carry this market
                anymore. Every time I pump, millions end up holding the bag at
                the top. I feel bad for them.
              </p>
              <p>
                <strong>CatTroll:</strong> Heh, a legend like you never cools
                down. But what do you mean? Don’t tell me you’re retiring?
              </p>
              <p>
                <strong>Doge:</strong> No, I’m not retiring, but I’m passing the
                torch to you. Cat, you’re clever, mischievous, and love to
                troll. You’re the perfect choice to lead the market and create a
                new wave of excitement. Let us rest for a while.
              </p>
              <p>
                <strong>CatTroll:</strong> Oho! So I’ll be the next legend? Will
                holders call me the "Cat King" or the "Troll Emperor"?
              </p>
              <p>
                <strong>Doge:</strong> Calm down, Cat. Just remember, I’m
                entrusting you with this power not to troll excessively. Troll
                responsibly—bring joy and enthusiasm to the market.
              </p>
              <p>
                <strong>CatTroll:</strong> Alright, I promise to troll just
                enough. But are you sure? If I take the lead, everyone’s going
                to the moon!
              </p>
              <div style={{ display: "none" }} className="story">
                <p>
                  <strong>Pepe:</strong> (emerging from the shadows) Heh, sounds
                  fun. Don’t forget, I’m also a legend who multiplied accounts X
                  times. Anyone mention me yet?
                </p>
                <p>
                  <strong>Doge:</strong> Oh, Pepe! I was just about to hand the
                  crypto torch to CatTroll. What’s your plan?
                </p>
                <p>
                  <strong>Pepe:</strong> Nothing much, I just want to join the
                  fun. Who can forget the days I turned thousands into
                  millionaires overnight? But I think the three of us teaming up
                  would be even better.
                </p>
                <p>
                  <strong>CatTroll:</strong> What does that mean? Are you
                  helping, or are you trolling more?
                </p>
                <p>
                  <strong>Pepe:</strong> Both. I’ll multiply accounts for the
                  holders and then troll lightly to see who’s smart enough to
                  exit at the right time.
                </p>
                <p>
                  <strong>Doge:</strong> That sounds good. CatTroll brings the
                  fun, Pepe tests greed, and together we’ll dominate the market.
                  The trio will “break” crypto.
                </p>
                <p>
                  <strong>CatTroll:</strong> Perfect! But remember, I’m leading
                  this time.
                </p>
                <p>
                  <strong>Pepe:</strong> Of course, Cat. I’ll just support, but
                  you have to deliver top-tier trolling.
                </p>
                <p>
                  <strong>Doge:</strong> Alright, it’s settled. TrollCoin will
                  be led by CatTroll, but we’ll support it to take it to the
                  moon—or beyond!
                </p>
                <p>
                  <strong>Pepe:</strong> Remember, Cat. If you succeed, you’ll
                  become a legend like us.
                </p>
                <p>
                  <strong>CatTroll:</strong> Deal! TrollCoin will be the symbol
                  of fun and intelligence in the crypto world.
                </p>
                <p>
                  <strong>Player A:</strong> TrollCoin just 5x’d! CatTroll is
                  fresh and amazing!
                </p>
                <p>
                  <strong>Player B:</strong> Pepe’s here too! This time, I’ll
                  definitely cash out at the right moment.
                </p>
                <p>
                  <strong>CatTroll:</strong> Hold on, but make sure you exit at
                  the right time. If you miss the peak, don’t blame Cat for not
                  warning you!
                </p>
                <p>
                  <strong>Pepe:</strong> Exactly. Enjoy the ride, but keep a
                  cool head!
                </p>
                <p>
                  <strong>Doge:</strong> That’s the spirit of crypto!
                </p>
              </div>
              <a className="read-more" href="#story" onClick={toggleStory}>
                Read More
              </a>
              <CountdownTimer endDate={endDate} />
              { window.IS_SHOW_CONTRACT && <CallToAction onlyButton={true} link={window.BUY_NOW_LINK} /> }
            </div>
          </div>
        </div>
      </section>

      {/* Liquidity locked */}
      <section className="container-fluid marketplace mt-20">
        <div className="box-right card card-white">
          <div className="row">
            <div className="col-md-8">
              <h3>Liquidity locked</h3>
              <p>
                Liquidity locked We burnt all LP tokens, therefore we cannot
                pull liquidity.
              </p>
            </div>
            <div className="col-md-4 text-right text-center">
              <img src={imglocked} width={150} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* Mechanism */}
      <section className="container-fluid marketplace mt-20">
        <div className="box-right card card-white">
          <div className="row">
            <div className="col-md-8">
              <h3>Mechanism</h3>
              <p>
                We are testing the cross-chain mechanism on CatTroll and the
                distribution strategy of $CatTroll is very organic and can be
                verified on the blockchain. Anyone can invest any amount.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img src={catlap} width={200} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* Community */}
      <section className="container-fluid marketplace mt-20">
        <div className="box-right card card-white">
          <div className="row">
            <div className="col-md-8">
              <h3>Community</h3>
              <p>
                Join us, along with our army of the fluffiest creatives, as we
                aim for the moon and build a warm, welcoming cat community on
                the way
              </p>
              <p>
                Now, our mission is to increase awareness of SOL fees so that
                everyone—especially artists—can finally get some respite from
                solana chain.
              </p>
            </div>
            <div className="col-md-4 text-center">
              <img src={imgCommunity} width={300} alt="chart market" />
            </div>
          </div>
        </div>
      </section>

      {/* Roadmap */}
      <section className="container-fluid roadmap">
        <div className="container">
          {renderTitle("Roadmap")}
          <RoadMap />
        </div>
      </section>

      {/* Call to Action */}
      {
         window.IS_SHOW_CONTRACT && <section className="container-fluid marketplace mt-20">
          <div className="box-right card card-white">
            <div className="row">
              <CallToAction link={window.BUY_NOW_LINK} />
            </div>
          </div>
        </section>
      }

      {/* Tab Video footer  */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 d-paddingRight-0">
            <EmbedVideo url="https://player.vimeo.com/video/923763172?h=da0ab1c016&badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&background=1" />
          </div>
          <div className="col-md-6 only-desktop " style={{ paddingLeft: 0 }}>
            <EmbedVideo url="https://player.vimeo.com/video/924122306?h=719d85fd8a&badge=0&autopause=0&player_id=0&app_id=58479&loop=1&autoplay=1&background=1" />
          </div>
        </div>
      </div>

      {/* Footer  */}
      <Footer />
    </div>
  );
};

export default Home;
